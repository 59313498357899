import SEO from '@components/Common/SEO/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import React, { useEffect, useState } from 'react';
import * as styles from './CrioReboot.module.scss';
import { graphql } from 'gatsby';
import FooterV2 from '@components/FooterV2/index';
import 'swiper/css/bundle';
import 'swiper/css';
import { StaticImage } from 'gatsby-plugin-image';
import Hero from '@components/CrioReboot/Hero/index';
import WhatsInStoreSection from '@components/CrioReboot/WhatsInStoreSection/index';
import ScheduleSection from '@components/CrioReboot/ScheduleSection/index';
import BenefitsSection from '@components/CrioReboot/BenefitsSection/index';
import RecapSection from '@components/CrioReboot/RecapSection/index';
import Navbar from '@components/CrioReboot/Navbar/index';

export default function CrioReboot({ data }) {
  const [navMobile, setNavMobile] = useState(false);
  const [animation, setAnimation] = useState('false');

  useEffect(() => {
    document.body.style['scroll-padding-top'] = '80px';
  }, []);

  const options = (
    <ul
      onClick={() => {
        setNavMobile(false);
      }}
      className="-mt-1 flex w-screen flex-col justify-center py-4 pl-0 text-white md:flex-row"
    >
      <li style={{ cursor: 'default' }}>
        <a href={'#top'} className="">
          <StaticImage
            placeholder="blurred"
            src="../../../images/CrioReboot/WhiteRebootLogo.webp"
            alt="Beaver"
            loading="lazy"
            style={{ width: '250px' }}
          ></StaticImage>
        </a>
      </li>
      <a href={'#schedule'} className="block py-6">
        Schedule
      </a>
      <a href={'#benefits'} className="block py-6">
        Benefits
      </a>
      <a href={'#recap'} className="block py-6">
        Recap
      </a>
    </ul>
  );

  return (
    <div className="pt-[110px] md:pt-[120px]">
      <SEO
        title="Reboot 2024 - Take Charge of your Career"
        description="Plan your next big career move in tech. Learn about emerging career opportunities, participate in learn by doing workshops, & get access to proven transition roadmaps. Build your career in 2024."
      ></SEO>
      {navMobile && (
        <div
          className={`${styles.navMobile} ${animation} flex flex-col items-center  bg-v5-green-300  text-center`}
        >
          <div className="" style={{ zIndex: '2' }}>
            <FontAwesomeIcon
              icon={['fal', 'times']}
              size="2x"
              className="cursor-pointer text-white"
              onClick={() => {
                setAnimation('animate__slideOutDown');
                setTimeout(() => {
                  setNavMobile(false);
                }, 700);
              }}
            />
            {options}
          </div>
        </div>
      )}
      <Navbar>
        <div
          onClick={() => {
            setNavMobile(true);
          }}
          className="block md:hidden"
        >
          <FontAwesomeIcon
            icon={['fas', 'bars']}
            size="2x"
            className="cursor-pointer text-white"
          />
        </div>
      </Navbar>
      <Hero />
      <WhatsInStoreSection />
      <div id="schedule"></div>
      <ScheduleSection />
      <div id="benefits"></div>
      <BenefitsSection />
      <div id="recap"></div>
      <RecapSection />
      <FooterV2
        variant="white"
        className="bg-[#009378] text-white"
        isRebootPage={true}
      />
    </div>
  );
}

// export default AboutUsPage;

export const pageQuery = graphql`
  query {
    ...CrioRebootFragment
  }
`;
