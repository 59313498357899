import React from 'react';
import BenefitCard from '../BenefitCard';
import Section from '../Section/index';
import {
  CrioBoxImage,
  GraduationCapImage,
} from '@src/constants/crio-reboot/index';

export default function BenefitsSection() {
  return (
    <Section
      className="bg-[#EDFFF7]"
      title={'Unlock Reboot 2024 benefits Worth ₹1,00,000'}
      description={
        <>
          Participate in the panel discussions to unlock limited-time benefits.
        </>
      }
      buttonLocation={'CRIO_REBOOT_2024_BENEFITS'}
    >
      <div className="my-[36px] flex w-full flex-col px-6 md:gap-14">
        <BenefitCard
          image={<CrioBoxImage />}
          title={'Exclusive Access to an Elective Program'}
          description={
            <>
              Exclusive access to an additional elective program on
              <br className="hidden md:block" /> ‘Design Foundations for New Age
              Products’
            </>
          }
        />
        <BenefitCard
          image={<GraduationCapImage />}
          title={'Scholarships upto Rs.60,000'}
        />
      </div>
    </Section>
  );
}
