import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export const CrioBoxImage = () => {
  return (
    <StaticImage
      imgStyle={{ objectFit: 'contain' }}
      loading="eager"
      src="../../images/CrioReboot/Box.webp"
      placeholder="blurred"
      className="h-full w-full"
      width={500}
      alt="Criodo"
    />
  );
};

export const GraduationCapImage = () => {
  return (
    <StaticImage
      imgStyle={{ objectFit: 'contain' }}
      loading="eager"
      src="../../images/CrioReboot/GraduationCap.webp"
      placeholder="blurred"
      className="h-full w-full"
      width={500}
      alt="Criodo"
    />
  );
};

export const RecapImage = () => {
  return (
    <StaticImage
      imgStyle={{ objectFit: 'contain' }}
      loading="eager"
      src="../../images/CrioReboot/Recap.webp"
      placeholder="blurred"
      className="h-full w-full"
      width={1500}
      alt="Criodo"
    />
  );
};

export const CompaniesImage = () => {
  return (
    <StaticImage
      imgStyle={{ objectFit: 'contain' }}
      loading="eager"
      src="../../images/CrioReboot/Companies.webp"
      placeholder="blurred"
      className="h-full w-full"
      width={1000}
      alt="Criodo"
    />
  );
};

export const EBenefits = [
  {
    image: (
      <StaticImage
        imgStyle={{ objectFit: 'contain' }}
        loading="eager"
        src="../../images/CrioReboot/Icon1.webp"
        placeholder="blurred"
        className="h-full w-full"
        width={500}
        alt="Criodo"
      />
    ),
    content: 'Explore latest tech trends with industry leaders',
  },
  {
    image: (
      <StaticImage
        imgStyle={{ objectFit: 'contain' }}
        loading="eager"
        src="../../images/CrioReboot/Icon2.webp"
        placeholder="blurred"
        className="h-full w-full"
        width={500}
        alt="Criodo"
      />
    ),
    content:
      'Guidance from recruiters on excelling interviews for top product roles',
  },
  {
    image: (
      <StaticImage
        imgStyle={{ objectFit: 'contain' }}
        loading="eager"
        src="../../images/CrioReboot/Icon3.webp"
        placeholder="blurred"
        className="h-full w-full"
        width={500}
        alt="Criodo"
      />
    ),
    content:
      'Career Transition roadmaps based on Crio’s curated learning & proven track record',
  },
  {
    image: (
      <StaticImage
        imgStyle={{ objectFit: 'contain' }}
        loading="eager"
        src="../../images/CrioReboot/Icon4.webp"
        placeholder="blurred"
        className="h-full w-full"
        width={500}
        alt="Criodo"
      />
    ),
    content: (
      <>
        Special Reboot 2024 benefits worth <strong>₹1,00,000</strong>
      </>
    ),
  },
];

export const ELinks = [
  {
    name: 'Schedule',
    link: '#schedule',
  },
  {
    name: 'Benefits',
    link: '#benefits',
  },
  {
    name: 'Recap',
    link: '#recap',
  },
];
