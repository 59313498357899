import React from 'react';
import LeadGenerationButton from '@components/LeadGenerationForm/LeadGenerationButton';
import strapiDb from '../../data/strapi_db.json';

const DiwaliTopStrip = () => {
  const { count } = strapiDb?.seatsLeft || { count: 150 };

  return (
    <LeadGenerationButton
      buttonLocation="CRIO_REBOOT_2024_HERO"
      formHeading={'Book your Slot'}
      pageOneButtonText={'Register Now'}
      pageTwoButtonText={'Register Now'}
      applicationPage={'CRIO_REBOOT'}
      type={'HiddenButton'}
    >
      <div className="flex min-h-[60px] w-screen cursor-pointer items-center justify-between gap-6 bg-[#004246] py-2 px-4 font-rubik text-[12px] text-[#F2FBF7] md:justify-center md:text-[18px]">
        <div className="">
          Reboot with{' '}
          <strong className="">Crio Career Diagnosis & Scholarship 2024</strong>{' '}
          <br className="block md:hidden" />
          <span className="hidden md:inline-block">|</span> Get up to{' '}
          <strong className="text-[#FFD500]">₹1,00,000 scholarship</strong> |{' '}
          <u>
            <strong>{count} Seats Left.</strong>
          </u>
        </div>
      </div>
    </LeadGenerationButton>
  );
};

export default DiwaliTopStrip;
