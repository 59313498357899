import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Autoplay, Navigation } from 'swiper';

import 'swiper/css/bundle';
import 'swiper/css/navigation';
import useResizer from '@components/extra/useResizer';
import SpeakerCard from './SpeakerCard';
import { StaticQuery, graphql } from 'gatsby';

export const pageQuery = graphql`
  query {
    allCrioRebootAssets: allFile(
      filter: { relativePath: { regex: "images/strapi/crio-reboot/" } }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            blurredOptions: {}
            width: 1000
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
        }
      }
    }
  }
`;

const SpeakerCarousel = ({ speakers }) => {
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
  const swiperRef = React.useRef(null);
  const isMobile = useResizer();

  const SLIDES_PER_VIEW_DESKTOP = 3;
  const settings = {
    slidesPerView: 1,
    breakpoints: {
      768: {
        slidesPerView: SLIDES_PER_VIEW_DESKTOP,
      },
    },
    id: 'speakers-carousel',
    loop: isMobile ? true : false,
    spaceBetween: 20,
    speed: 1500,
    grabCursor: true,
    autoplay: {
      delay: 7000,
      disableOnInteraction: true,
    },
    enabled: speakers.length > SLIDES_PER_VIEW_DESKTOP || isMobile,
  };

  return (
    <StaticQuery
      query={pageQuery}
      render={(data) => {
        return (
          <div
            className="relative mx-auto w-full md:max-w-[80%]"
            id="speakers-carousel"
          >
            <Swiper
              ref={swiperRef}
              {...settings}
              navigation={{
                prevEl: navigationPrevRef.current,
                nextEl: navigationNextRef.current,
              }}
              autoplay
              modules={[Navigation, Autoplay]}
              className={`rebootSpeakerCarousal flex items-center justify-center md:mr-0 md:max-w-full`}
            >
              {speakers.map((el) => {
                return (
                  <SwiperSlide
                    key={el.title + new Date().getTime()}
                    className="flex items-center justify-center"
                  >
                    <SpeakerCard
                      title={el.name}
                      image={el.image}
                      description={el.description}
                      companies={el.Companies}
                      imagesData={data}
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
            {(speakers.length > SLIDES_PER_VIEW_DESKTOP || isMobile) && (
              <div className="hidden md:block">
                <div
                  onClick={() => swiperRef.current.swiper.slidePrev()}
                  ref={navigationPrevRef}
                  className="absolute top-1/2 z-10 -translate-x-1/2 -translate-y-1/2 transform cursor-pointer  hover:opacity-75 sm:-left-10"
                >
                  <FontAwesomeIcon
                    icon={['fas', 'chevron-left']}
                  ></FontAwesomeIcon>
                </div>{' '}
                <div
                  onClick={() => swiperRef.current.swiper.slideNext()}
                  ref={navigationNextRef}
                  className="absolute top-1/2 right-0 z-10 -translate-x-1/2 -translate-y-1/2 transform cursor-pointer hover:opacity-75 sm:-right-10"
                >
                  <FontAwesomeIcon
                    icon={['fas', 'chevron-right']}
                  ></FontAwesomeIcon>
                </div>
              </div>
            )}
            <style>{`
    .rebootSpeakerCarousal .swiper-wrapper {
        display: flex;
    }

    @media only screen and (min-width: 768px) {
      .rebootSpeakerCarousal .swiper-wrapper {
        display: flex;
        justify-content: center;
    }
    }
    `}</style>
          </div>
        );
      }}
    ></StaticQuery>
  );
};

export default SpeakerCarousel;
