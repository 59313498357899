import React from 'react';

export default function Text({ variant, className, children }) {
  const getTextByVairant = () => {
    switch (variant) {
      case 'h1':
        return (
          <h1
            className={`font-rubik text-[1.75rem] font-bold md:text-[2.75rem] md:font-semibold  ${className}`}
          >
            {children}
          </h1>
        );
      case 'h2':
        return (
          <h2
            className={`font-rubik text-[1.5rem] font-bold md:text-[2.75rem] md:font-semibold ${className}`}
          >
            {children}
          </h2>
        );
      case 'h3':
        return (
          <h2
            className={`font-manrope text-[1.125rem] font-bold md:text-[2.125rem] ${className}`}
          >
            {children}
          </h2>
        );
      case 'h4':
        return (
          <h2
            className={`font-manrope text-[1.125rem] font-bold md:text-[1.5rem] ${className}`}
          >
            {children}
          </h2>
        );
      default:
        return (
          <p className={`font-manrope text-[1rem] md:text-xl ${className}`}>
            {children}
          </p>
        );
    }
  };

  return getTextByVairant();
}
