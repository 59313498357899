import React from 'react';
import Section from '../Section/index';
import Text from '../Typography/Text/index';
import { CompaniesImage, RecapImage } from '@src/constants/crio-reboot/index';

export default function RecapSection() {
  return (
    <Section className="" buttonLocation={'CRIO_REBOOT_2024_RECAP'}>
      <div className="mb-6 flex w-full flex-col items-center justify-center gap-6 rounded-[10px] bg-[#EDFFF7] py-10 px-5 md:px-16">
        <Text variant="h2" className="text-center">
          Reboot 2023 Recap
        </Text>
        <a href="https://youtu.be/XVHY5ibyt0k" target="_blank" rel="noreferrer">
          <div className="h-[50px] w-[260px] md:h-[150px] md:w-[600px]">
            <RecapImage />
          </div>
        </a>
        <Text className="my-4 text-center">
          Last year, Reboot 2023 was nothing short of a tech revolution! Many
          seized the opportunity to explore new career paths and witnessed
          industry leaders discuss the tech innovations across industries. Join
          us this year for Reboot 2024 and discover the roadmap to a successful
          career in tech.
        </Text>
        <div className="flex flex-col gap-8 md:flex-row md:gap-14">
          <div className="flex flex-1  flex-col items-center justify-center gap-2 rounded-[10px] bg-white p-8">
            <Text variant="h2" className="text-center">
              2500+
            </Text>
            <Text className="text-center">
              Attendees from across the country
            </Text>
          </div>
          <div className="flex flex-1 flex-col items-center justify-center gap-2 rounded-[10px] bg-white p-8">
            <div className="h-[60px] w-[100px] md:w-[200px]">
              <CompaniesImage />
            </div>
            <Text className="text-center">
              10+ speakers from top product companies
            </Text>
          </div>
        </div>
      </div>
    </Section>
  );
}
