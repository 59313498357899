import LeadGenerationButton from '@components/LeadGenerationForm/LeadGenerationButton';
import React from 'react';
import Text from '../Typography/Text/index';
import { StaticImage } from 'gatsby-plugin-image';
import { ELinks } from '@src/constants/crio-reboot/index';
import DiwaliTopStrip from '@components/DiwaliTopStrip/index';

export default function Navbar({ children }) {
  return (
    <div className="fixed top-0 left-0 z-[1000]">
      <DiwaliTopStrip />
      <div className="flex h-[50px] w-screen items-center justify-between bg-v5-green-300 p-2 px-4 md:h-[80px]">
        <a href="/">
          <div className="h-full w-[100px] md:w-[200px]">
            <StaticImage
              imgStyle={{ objectFit: 'contain' }}
              loading="eager"
              src="../../../images/CrioReboot/CrioLogo.webp"
              placeholder="blurred"
              width={600}
              alt="Criodo"
            />
          </div>
        </a>
        {children}
        <div className="hidden items-center gap-10 md:flex">
          {ELinks.map((link) => (
            <a href={link.link}>
              <Text
                key={link.name}
                className="cursor-pointer font-bold text-white"
              >
                {link.name}
              </Text>
            </a>
          ))}
          <LeadGenerationButton
            type="Register Now"
            buttonLocation="CRIO_REBOOT_2024_NAVBAR"
            formHeading={'Book your Slot'}
            pageOneButtonText={'Register Now'}
            pageTwoButtonText={'Register Now'}
            applicationPage={'CRIO_REBOOT'}
          />
        </div>
      </div>
    </div>
  );
}
