import React from 'react';
import Text from '../Typography/Text/index';
import { GatsbyImage, StaticImage, getImage } from 'gatsby-plugin-image';

export default function SpeakerCard({
  title,
  description,
  companies,
  image,
  imagesData,
}) {
  const getSpeakerImage = (image) => {
    const speakerImageData = imagesData.allCrioRebootAssets.nodes.find(
      (ele) => ele.name === image.data?.attributes?.hash,
    );

    let speakerImage;

    if (speakerImageData) {
      speakerImage = getImage(speakerImageData?.childImageSharp);
    }

    return image?.data ? (
      <GatsbyImage
        loading="eager"
        image={speakerImage}
        alt={'company'}
        style={{ maxHeight: '100%' }}
        imgStyle={{ objectFit: 'contain' }}
      />
    ) : (
      <StaticImage
        loading="eager"
        src="../../../images/CrioReboot/SpeakerPlaceholder.webp"
        placeholder="blurred"
        alt="BVS"
      />
    );
  };

  const getCompanyImage = (company) => {
    const companyImageData = imagesData.allCrioRebootAssets.nodes.find((ele) =>
      ele.name.includes(company?.name),
    );

    const companyImage = getImage(companyImageData?.childImageSharp);

    return (
      <div className="h-[40px] w-[120px] rounded-[4px]" key={company.name}>
        <GatsbyImage
          loading="eager"
          image={companyImage}
          alt={'company'}
          style={{ maxHeight: '100%' }}
          imgStyle={{ objectFit: 'contain' }}
        />
      </div>
    );
  };

  return (
    <div className="flex min-h-[320px] w-full max-w-[300px] flex-col items-center gap-4 rounded-[10px] bg-white p-4 md:min-h-[410px] md:py-8 md:px-6">
      <div className="h-[140px] w-[140px] bg-blue-100">
        {getSpeakerImage(image)}
      </div>
      <Text variant="h4" className="text-center">
        {title}
      </Text>
      <Text className="h-auto text-center md:h-[64px]">
        <span dangerouslySetInnerHTML={{ __html: description }}></span>
      </Text>
      <div className="flex items-center justify-center gap-4">
        {companies?.map((company) => getCompanyImage(company))}
      </div>
    </div>
  );
}
