import React from 'react';
import Text from './Typography/Text/index';

export default function BenefitCard({ className, image, title, description }) {
  return (
    <div className="relative mt-10 flex w-full flex-col items-center justify-center gap-6 rounded-[10px] border-[2px] border-v5-green-300 border-opacity-40 p-4 pt-16 pb-6 md:pt-16">
      <div className="absolute -top-[30px] left-1/2 h-[60px] w-[60px] -translate-x-1/2 rounded-full bg-[#EDFFF7] md:-top-[40px] md:h-[80px] md:w-[80px]">
        {image}
      </div>
      <Text variant="h3" className="text-center">
        {title}
      </Text>
      {description && (
        <div className="flex w-[80%] items-center justify-center rounded-[10px] bg-white p-4 py-11">
          <Text className="text-center">{description}</Text>
        </div>
      )}
    </div>
  );
}
