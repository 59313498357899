import React from 'react';
// import { getImage } from 'gatsby-plugin-image';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Autoplay, Navigation } from 'swiper';

import 'swiper/css/bundle';
import 'swiper/css/navigation';
// import useResizer from '@components/extra/useResizer';
// import RawHTML from '@components/Common/RawHTML/index';
import ScheduleCard from './ScheduleCard';
import strapiDb from '../../../data/strapi_db.json';

const ScheduleCarousel = () => {
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
  const swiperRef = React.useRef(null);

  const schedule = strapiDb.crioRebootSchedule.data[0]?.attributes.Schedule;

  const settings = {
    slidesPerView: 1,
    id: 'schedule-carousel',
    loop: true,
    spaceBetween: 10,
    speed: 1000,
    grabCursor: true,
    autoplay: {
      delay: 7000,
      disableOnInteraction: true,
    },
  };

  return (
    <div
      className="relative mx-auto w-full md:max-w-[1336px]"
      id="projects-carousel"
    >
      <Swiper
        ref={swiperRef}
        {...settings}
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        autoplay
        modules={[Navigation, Autoplay]}
        className={`projectCarousal w-[80vw] md:mr-0 md:max-w-full`}
      >
        {schedule.map((el) => {
          return (
            <SwiperSlide
              key={el.title + new Date().getTime()}
              className="flex items-center justify-center"
            >
              <ScheduleCard
                title={el.title}
                description={el.description}
                data={el}
                time={el.time}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
      {
        <div className="">
          <div
            onClick={() => swiperRef.current.swiper.slidePrev()}
            ref={navigationPrevRef}
            className="absolute top-1/2 -left-5 z-10 -translate-x-1/2 -translate-y-1/2 transform  cursor-pointer hover:opacity-75 md:-left-10"
          >
            <FontAwesomeIcon icon={['fas', 'chevron-left']}></FontAwesomeIcon>
          </div>
          <div
            onClick={() => swiperRef.current.swiper.slideNext()}
            ref={navigationNextRef}
            className="absolute top-1/2 right-0 -right-7 z-10 -translate-x-1/2 -translate-y-1/2 transform cursor-pointer hover:opacity-75 md:-right-10"
          >
            <FontAwesomeIcon icon={['fas', 'chevron-right']}></FontAwesomeIcon>
          </div>
        </div>
      }
    </div>
  );
};

export default ScheduleCarousel;
