import LeadGenerationButton from '@components/LeadGenerationForm/LeadGenerationButton';
import React from 'react';
import Text from '../Typography/Text/index';

export default function Section({
  className,
  children,
  title,
  description,
  id,
  buttonLocation,
}) {
  return (
    <div className={`w-screen ${className}`} id={id}>
      <div
        className={`container flex flex-col items-center justify-center py-10 md:py-24`}
      >
        {title && (
          <Text
            variant="h2"
            className="mb-[36px] text-center capitalize md:mb-16"
          >
            {title}
          </Text>
        )}
        {description && <Text className="text-center">{description}</Text>}

        {children}
        <LeadGenerationButton
          type="Register Now"
          buttonLocation={buttonLocation || 'CRIO_REBOOT'}
          formHeading={'Book your Slot'}
          pageOneButtonText={'Register Now'}
          pageTwoButtonText={'Register Now'}
          applicationPage={'CRIO_REBOOT'}
        />
      </div>
    </div>
  );
}
