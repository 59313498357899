import LeadGenerationButton from '@components/LeadGenerationForm/LeadGenerationButton';
import React from 'react';
import Text from '../Typography/Text/index';
import { StaticImage } from 'gatsby-plugin-image';

export default function Hero() {
  const description = [
    'Personalised career planning to bag a top tech role in 2024',
    'Unlocking the ultimate roadmap to cracking tech career opportunities with Striver, SWE-III at Google',
    'Networking opportunities with recruiters from big leagues',
  ];

  return (
    <div className="container flex flex-col gap-8 py-24 md:flex-row md:py-20">
      <div className="flex flex-col items-center gap-6 md:items-start md:gap-10">
        <div className="w-[200px] md:w-[400px]">
          <StaticImage
            imgStyle={{ objectFit: 'contain' }}
            loading="eager"
            src="../../../images/CrioReboot/RebootLogo.webp"
            placeholder="blurred"
            width={1600}
            alt="Criodo"
          />
        </div>
        <Text variant="h1" className="text-center md:text-left">
          Plan Your Next Big Career Move in Tech
        </Text>
        <div>
          <Text className="text-center md:text-left">
            <strong>Join us at this career conclave for:</strong>
          </Text>
          <ul className="pl-8">
            {description.map((content) => (
              <li key={content}>
                <Text>{content} </Text>
              </li>
            ))}
          </ul>
        </div>
        <div className="flex w-[250px] justify-center md:justify-start">
          <LeadGenerationButton
            type="Register Now"
            buttonLocation="CRIO_REBOOT_2024_HERO"
            formHeading={'Book your Slot'}
            pageOneButtonText={'Register Now'}
            pageTwoButtonText={'Register Now'}
            applicationPage={'CRIO_REBOOT'}
          />
        </div>
      </div>
      <div className="h-full w-full">
        <StaticImage
          imgStyle={{ objectFit: 'cover' }}
          loading="eager"
          src="../../../images/CrioReboot/Hero.webp"
          placeholder="blurred"
          className="h-auto w-full md:h-[600px] md:translate-x-32 md:scale-105"
          width={1600}
          alt="Criodo"
        />
      </div>
    </div>
  );
}
