import { format } from 'date-fns';
import React from 'react';
import SpeakerCarousel from '../SpeakerCarousel/index';
import Text from '../Typography/Text/index';
import { StaticImage } from 'gatsby-plugin-image';

export default function ScheduleCard({ title, description, time, data }) {
  const speakers = data.Speakers;

  return (
    <div className="mb-6 flex w-full flex-col items-center justify-center gap-3 rounded-[10px] bg-[#EDFFF7] py-10 px-5 pt-12 md:gap-6 md:px-16">
      <div className="absolute top-0 right-0 flex h-[30px] w-[100px] items-center justify-center gap-2 rounded-tr-[10px] rounded-bl-[16px] bg-[#04D2AF] bg-opacity-20 md:h-[50px] md:w-[160px] md:rounded-bl-[32px]">
        <div className="h-3 w-3 rounded-full bg-[#2B9173] drop-shadow-md"></div>
        <Text>Online</Text>
      </div>
      <Text variant="h3" className="text-center">
        {title}
      </Text>
      <div className="flex flex-col gap-3 md:mt-6 md:flex-row md:gap-4">
        <Text className="mt-2 w-full text-center md:w-3/4 md:text-left">
          {description}
        </Text>
        <div className="h-[1px] w-full bg-v5-green-300 md:h-auto md:w-[1px]"></div>
        <div className="flex w-full flex-row justify-around gap-4 md:w-1/4 md:flex-col md:py-4">
          {[
            {
              value: format(new Date(time), 'dd MMMM, yyyy'),
              image: (
                <StaticImage
                  imgStyle={{ objectFit: 'contain' }}
                  loading="eager"
                  src="../../../images/CrioReboot/Icons/Calendar.svg"
                  placeholder="blurred"
                  className="h-full w-full"
                  width={500}
                  alt="Calendar"
                />
              ),
            },
            {
              value: format(new Date(time), 'hh:mm aaa'),
              image: (
                <StaticImage
                  imgStyle={{ objectFit: 'contain' }}
                  loading="eager"
                  src="../../../images/CrioReboot/Icons/Clock.svg"
                  placeholder="blurred"
                  className="h-full w-full"
                  width={500}
                  alt="Clock"
                />
              ),
            },
          ].map((el) => (
            <Text className="flex items-center gap-3">
              <div className="h-[30px] w-[30px]">{el.image}</div>
              {el.value}
            </Text>
          ))}
        </div>
      </div>
      <div className="h-[1px] w-full bg-v5-green-300"></div>
      <div className="w-full">
        <SpeakerCarousel speakers={speakers} />
      </div>
    </div>
  );
}
