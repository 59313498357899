import React from 'react';
import ScheduleCarousel from '../ScheduleCarousel/index';
import Section from '../Section/index';

export default function ScheduleSection() {
  return (
    <Section
      className=""
      title={'Schedule'}
      id="schedule"
      buttonLocation={'CRIO_REBOOT_2024_SCHEDULE'}
    >
      <div className="mb-6">
        <ScheduleCarousel
          title={''}
          description={
            <>
              Join the recruiters roundtable as they discuss the essential
              skills of software developers in 2024, and valuable tips and
              insights to enhance your job search process.
            </>
          }
        />
      </div>
    </Section>
  );
}
