import { EBenefits } from '@src/constants/crio-reboot/index';
import React from 'react';
import Section from '../Section/index';
import Text from '../Typography/Text/index';

export default function WhatsInStoreSection() {
  return (
    <Section
      className="bg-[#EDFFF7]"
      title={'What’s in Store for you?'}
      description={
        <>
          Reboot 2024 is your gateway to a thriving career in tech.
          <br className="hidden md:block" /> Plan your future in this career
          conclave and seize exciting career opportunities in 2024!
        </>
      }
      buttonLocation={'CRIO_REBOOT_2024_WHATS_IN_STORE_SECTION'}
    >
      <div className="my-[36px] grid grid-cols-1 gap-[14px] md:my-16 md:grid-cols-2 md:gap-16">
        {EBenefits.map((benefit) => (
          <div className="flex items-center gap-[24px] md:gap-10">
            <div className="h-[70px] w-[70px] rounded-[10px] bg-white md:h-28 md:w-28">
              {benefit.image}
            </div>
            <Text className="mt-1 flex-1">{benefit.content}</Text>
          </div>
        ))}
      </div>
    </Section>
  );
}
